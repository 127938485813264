<template>
  <div class="container">
    <div id="jstree"></div>
  </div>
</template>

<script>
/* eslint-disable */

// import { jstreeData } from '../assets/js/constants.js';

export default {
  data() {
    return {
      selected: [],
      jstreeData: [],
    };
  },
  mounted() {
    // this.initialTreeview();

    // access tree-data with api
    const BookTreeAPI = `${process.env.VUE_APP_APIPATH}api/booktree/`;
    console.log(BookTreeAPI);
    this.$http.get(BookTreeAPI).then((response) => {
      // console.log(response.data);
      const booktreeData = response.data;

      booktreeData.forEach((item) => {
        // console.log('xxx', item);
        let parent = item.parentId;
        if (parent === '') {
          parent = '#';
        }

        const leaf_obj = {
          id: item.bookId,
          parent: parent,
          text: item.name,
          icon: 'false',
          state: {
            opened: 'true',
          },
        };
        this.jstreeData.push(leaf_obj);
      });
      // console.log('xxx', this.jstreeData);
      this.initialTreeview();
    });
  },
  methods: {
    initialTreeview() {
      const vm = this;
      $(function () {
        // 6 create an instance when the DOM is ready
        $('#jstree').jstree({
          plugins: ['wholerow', 'checkbox'],
          core: {
            data: vm.jstreeData,
          },
        });
        // 7 bind to events triggered on the tree
        $('#jstree').on('changed.jstree', function (e, data) {
          // console.log(data.selected);
          vm.selected = [];
          for (let i = 0; i < data.selected.length; i++) {
            const nodePath = data.instance.get_path(data.selected[i]);
            if (nodePath.length == 3) {
              vm.selected.push(nodePath);
            }
          }
          // console.log(vm.selected);
          vm.$emit('update-js-tree', vm.selected);
        });
        // 8 interact with the tree - either way is OK
        $('button').on('click', function () {
          $('#jstree').jstree(true).select_node('child_node_1');
          $('#jstree').jstree('select_node', 'child_node_1');
          $.jstree.reference('#jstree').select_node('child_node_1');
        });
      });
    },
  },
};
</script>

<style>
</style>