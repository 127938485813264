<template>
  <div id="main">
    <div class="container-md">
      <i-navbar ref="nav"></i-navbar>
      <div class="row mt-5 vh-100">
        <div class="col-4">
          <div class="row">
            <div class="col">
              <div class="search_section text-left">
                <span class="title d-block">關鍵字</span>
                <div class="input-group underline_div">
                  <input
                    class="d-block mt-2"
                    placeholder="請輸入關鍵字或選擇主題"
                    type="text"
                    v-model="keyword"
                  />
                  <i
                    type="button"
                    class="fa fa-chevron-down mt-2 text-right dropdown_icon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-hidden="true"
                  >
                    <span class="sr-only">Toggle Dropdown</span>
                  </i>
                  <div class="dropdown-menu dropdown-menu-right dropdown_div">
                    <a
                      v-for="item in topicKeywordList"
                      class="dropdown-item px-1"
                      href="#"
                      :key="item.topic"
                      @click.prevent="keyword = item.topic"
                      >{{ item.topic }}</a
                    >
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-info rounded-pill search_btn"
                  @click="searchKeyword"
                >
                  搜 尋
                </button>
                <!-- <span class="title d-block">相關人物</span> -->

                <!-- relative person -->
                <div
                  id="related_person"
                  v-if="pageType == 'person' && personPageType == 'info'"
                >
                  <span class="title d-block">相關人物</span>
                  <div class="pl-3 mt-1">
                    <a
                      href="#"
                      class="d-block"
                      v-for="name in relatedPerson"
                      :key="name"
                      @click.prevent="searchPerson(name)"
                      >{{ name }}</a
                    >
                  </div>
                </div>

                <!-- fulltext dir disable temporary -->
                <div
                  id="fulltext_dir"
                  v-show="pageType == 'full_text' || pageType == 'desp'"
                  ref="fulltext_dir"
                >
                  <span class="title d-block mb-2">葉德輝著作集全文</span>
                  <i-treeview
                    @update-js-tree="filterFullTextSearchResult"
                  ></i-treeview>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col">
              <div
                id="desp_section"
                class="text-left"
                v-if="pageType == 'desp'"
              >
                <p class="title font-weight-bold">資料庫簡介</p>
                <p class="content">
                  本資料庫為整合研究團隊以葉德輝為核心所建立之全文、書目與人名資料庫，並以跨庫檢索模式讓使用者便捷地取得所需資訊。
                </p>
                <p class="title font-weight-bold">
                  ●
                  <span
                    class="text-white rounded-pill database_btn px-3"
                    type="button"
                    @click="pageType = 'full_text'"
                  >
                    全文資料庫
                  </span>
                </p>
                <!-- eslint-disable-next-line max-len -->
                <p class="content">
                  全文資料庫可檢索之內容為取材至《郋園先生全書》之文集、詩集與讀書札記，清單如下：
                  文集（郋園山居文錄、觀古堂文外集、觀古堂騈儷文、郋園論學書劄）、詩集（古泉雜詠、消夏百一詩、觀畫百詠、昆侖皕詠集、
                  曲中九友詩、南遊集、朱亭集、書空集、歲寒集、漢上集、于京集、還吳集、北征集、浮湘集、觀劇絕句）、讀書札記（書林清話、郋園讀書志）
                </p>
                <p class="title font-weight-bold">
                  ●
                  <span
                    class="text-white rounded-pill database_btn px-3"
                    type="button"
                    @click="pageType = 'book'"
                  >
                    書目資料庫
                  </span>
                </p>
                <p class="content">
                  本資料庫可檢索葉德輝收錄於《觀古堂藏書目》、《郋園讀書志》與《印刻書出版書目》中的書目資料
                </p>
                <p class="title font-weight-bold">
                  ●
                  <span
                    class="text-white rounded-pill database_btn px-3"
                    type="button"
                    @click="pageType = 'person'"
                  >
                    人名資料庫
                  </span>
                </p>
                <p class="content">
                  人名資料庫可用以檢索與葉德輝相關之人物資料
                </p>
              </div>

              <div
                id="search_result_section"
                class="right-column"
                v-if="pageType != 'desp'"
              >
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      :class="{ 'full-text-active': pageType == 'full_text' }"
                      href="#"
                      @click.prevent="pageType = 'full_text'"
                      >全文資料庫 ({{
                        this.fullTextSearchResultList.length
                      }}筆)</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      :class="{ 'book-database-active': pageType == 'book' }"
                      href="#"
                      @click.prevent="pageType = 'book'"
                      >書目資料庫({{ this.bookSearchResultList.length }}筆)</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      :class="{
                        'person-database-active': pageType == 'person',
                      }"
                      href="#"
                      @click.prevent="pageType = 'person'"
                      >人名資料庫({{ this.personSearchResultList.length }}筆)</a
                    >
                  </li>
                </ul>
                <div
                  v-if="pageType == 'full_text'"
                  :class="{ full_text_section: pageType == 'full_text' }"
                  class="text-left"
                >
                  <div class="title py-1">
                    <span>全文資料庫說明</span>
                  </div>

                  <div class="content py-2">
                    <span
                      >全文資料庫可檢索之內容為取材至《郋園先生全書》之文集、詩集與讀書札記，清單如下：文集（郋園山居文錄、觀古堂文外集、觀古堂騈儷文、郋園論學書劄）、
                      詩集（古泉雜詠、消夏百一詩、觀畫百詠、昆侖皕詠集、曲中九友詩、南遊集、朱亭集、書空集、歲寒集、漢上集、于京集、還吳集、北征集、浮湘集、觀劇絕句）、
                      讀書札記（書林清話、郋園讀書志）</span
                    >
                  </div>

                  <div class="title py-1">
                    <span>檢索結果</span>
                  </div>

                  <div class="text_result_section">
                    <div
                      class="mt-3 px-3"
                      v-for="(item, index) in filterFullTextSearchResultList"
                      :key="index"
                    >
                      <p class="text-result-title font-weight-bold">
                        {{ index + 1 }}. <span v-html="item.title"></span>
                        <span class="ml-3">
                          <img
                            v-if="item.isContainImg"
                            type="button"
                            class="d-inline-block mr-2"
                            src="../assets/img/image_text_read.png"
                            alt="..."
                            @click="openBookInfo('fulltext', item.title)"
                          />
                          <img
                            type="button"
                            class="d-inline-block"
                            src="../assets/img/bookinfo_icon.png"
                            alt="..."
                            @click="dbsearch('bookinfo', item.title)"
                          />
                        </span>
                      </p>
                      <p
                        class="text-result-content"
                        v-html="item.contentWithKeyword || item.content"
                      ></p>
                      <span class="fulltext_hide_show_btn d-block text-right"
                        ><a
                          href="#"
                          class=""
                          @click.prevent="openSingleFullText(item)"
                          >展開文本</a
                        >
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="singleFullTextState == 'on'"
                  id="fulltext_overlap_div"
                  class="text-left"
                >
                  <i
                    type="button"
                    class="fa fa-times-circle h2 float-right px-3 py-2"
                    @click="singleFullTextState = 'off'"
                  ></i>
                  <p class="title h3" v-html="singleFullText.title"></p>
                  <p class="content" v-html="singleFullText.content">content</p>
                </div>

                <div
                  v-if="pageType == 'book'"
                  :class="{ book_database_section: pageType == 'book' }"
                  class="text-left"
                >
                  <div class="title text-left py-1">
                    <span>書目資料庫說明</span>
                  </div>

                  <div class="content text-left py-2">
                    <span
                      >本資料庫可檢索葉德輝收錄於《觀古堂藏書目》、《郋園讀書志》與《印刻書出版書目》中的書目資料</span
                    >
                  </div>

                  <div class="title text-left py-1">
                    <span>檢索結果</span>
                  </div>

                  <div class="book_result_section mt-3 px-5">
                    <div
                      v-for="(item, index) in bookSearchResultList"
                      :key="index"
                    >
                      <p class="title-text font-weight-bold">
                        {{ index + 1 }}. {{ item.title }}
                        <span class="ml-3">
                          <img
                            type="button"
                            class="d-inline-block mr-2"
                            src="../assets/img/fulltext_search_icon.png"
                            alt="..."
                            @click="dbsearch('fulltext', item.bookName)"
                          />
                          <img
                            v-if="item.isContainImg"
                            type="button"
                            class="d-inline-block"
                            src="../assets/img/image_text_read.png"
                            alt="..."
                            @click="openBookInfo('book', item.bookName)"
                          />
                        </span>
                      </p>
                      <table class="table table-borderless text-center">
                        <thead class="bg-white">
                          <tr>
                            <th scope="col">四部</th>
                            <th scope="col">類目</th>
                            <th scope="col">次類</th>
                            <th scope="col">書名</th>
                            <th scope="col">卷數</th>
                            <th scope="col">作者</th>
                            <th scope="col">版本</th>
                          </tr>
                        </thead>
                        <tbody class="first_table_td">
                          <tr>
                            <td v-html="item.fourClass"></td>
                            <td v-html="item.mainCategory"></td>
                            <td v-html="item.subCategory"></td>
                            <td v-html="item.bookName"></td>
                            <td v-html="item.bookNums"></td>
                            <td v-html="item.author"></td>
                            <td v-html="item.version"></td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <table class="table table-bordered mt-1 bg-light">
                        <tbody>
                          <tr>
                            <td class="first_td"><span>譯者/筆述者</span></td>
                            <td class="text-left secodn_td">
                              <span v-html="item.translator"></span>
                            </td>
                          </tr>

                          <tr>
                            <td class="first_td"><span>注疏者</span></td>
                            <td class="text-left secodn_td">
                              <span v-html="item.annotator"></span>
                            </td>
                          </tr>

                          <tr>
                            <td class="first_td"><span>編撰者</span></td>
                            <td class="text-left secodn_td">
                              <span v-html="item.writer"></span>
                            </td>
                          </tr>

                          <tr>
                            <td class="first_td"><span>題面者</span></td>
                            <td class="text-left secodn_td">
                              <span v-html="item.topic"></span>
                            </td>
                          </tr>

                          <tr>
                            <td class="first_td"><span>牌記</span></td>
                            <td class="text-left secodn_td">
                              <span v-html="item.bookmark"></span>
                            </td>
                          </tr>

                          <tr>
                            <td class="first_td"><span>序跋</span></td>
                            <td class="text-left secodn_td">
                              <span v-html="item.preface"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  v-if="pageType == 'person'"
                  :class="{ person_database_section: pageType == 'person' }"
                  class="text-left"
                >
                  <div class="title text-left py-1">
                    <span>人名資料庫說明</span>
                  </div>

                  <div class="content text-left py-2">
                    <span>人名資料庫可用以檢索與葉德輝相關之人物資料</span>
                  </div>

                  <div class="title text-left py-1">
                    <span
                      >檢索結果
                      <span
                        class="badge badge-secondary"
                        type="button"
                        v-if="personPageType != 'list'"
                        @click.prevent="personPageType = 'list'"
                        >返回人物列表</span
                      ></span
                    >
                  </div>

                  <div class="person_result_section mt-3 px-5">
                    <table
                      class="table table-striped text-center"
                      v-if="personPageType == 'list'"
                    >
                      <thead class="bg-white">
                        <tr>
                          <th scope="col" class="text-short">序號</th>
                          <th scope="col" class="text-short">姓名</th>
                          <th scope="col" class="text-short">籍貫</th>
                          <th scope="col" class="text-long">別名</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in personSearchResultList"
                          :key="index"
                        >
                          <th scope="row">{{ index + 1 }}</th>
                          <td>
                            <a
                              href="#"
                              @click.prevent="searchPerson(item.name)"
                              v-html="item.name"
                            ></a>
                          </td>
                          <td v-html="item.address"></td>
                          <td v-html="item.nickname"></td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="p-3" v-if="personPageType == 'info'">
                      <!-- person table -->
                      <div id="person_table">
                        <span class="title d-block py-1">人物資料表</span>
                        <table class="table table-bordered mt-1">
                          <tbody>
                            <tr>
                              <td class="first_td"><span>姓 名</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.name }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>別 名</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.nickname }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>性 別</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.gender }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>國 籍</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.country }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>籍 貫</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.address }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>生 年</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.birth }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>卒 年</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.death }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>年 齡</span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.age }}</span>
                              </td>
                            </tr>

                            <tr>
                              <td class="first_td"><span>官 名 </span></td>
                              <td class="text-left secodn_td">
                                <span>{{ personInfo.position }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <!-- person biography -->
                      <div id="person_biography">
                        <span class="title d-block py-1"
                          >人物小傳
                          <span
                            data-v-36732240=""
                            type="button"
                            class="badge badge-secondary"
                            @click="linkToPerson(personInfo.name)"
                          >
                            人物小傳
                          </span>
                        </span>
                        <div
                          class="content p-2 mt-1 text-left"
                          v-html="personInfo.content"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      id="go_back_btn"
      type="button"
      class="btn btn-info"
      v-if="pageType != 'desp'"
      @click="pageType = 'desp'"
    >
      回到資料庫簡介
    </button>
    <i-footer></i-footer>
  </div>
</template>

<script>
/* eslint-disable */

import $ from 'jquery';
import navbar from '../components/Navbar.vue';
import footer from '../components/Footer.vue';
import treeview from '../components/Treeview.vue';

export default {
  mounted() {
    this.$refs.nav.isActiveList = [0, 0, 0, 0, 0, 0, 0, 0];
    this.$refs.nav.isActiveList[6] = 1;

    const csrftoken = this.getCookie('csrftoken');
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
      withCredentials: true,
    };
    // console.log('xxx-headers', headers);

    const topicKeywordsAPI = `${process.env.VUE_APP_APIPATH}api/topic-keywords/`;
    console.log(topicKeywordsAPI);
    this.$http.get(topicKeywordsAPI).then((response) => {
      // console.log(response.data);
      const currTopicKeywords = [];
      response.data.forEach((item) => {
        const topicKeywords = {};
        topicKeywords['topic'] = item.topic;
        topicKeywords['keywords'] = item.keywords.split('、');
        currTopicKeywords.push(topicKeywords);
      });
      this.topicKeywordList = currTopicKeywords;
      // console.log('xxx', this.topicKeywordList);
    });

    if (this.$route.query.type && this.$route.query.keyword) {
      const type = `${this.$route.query.type}`;
      const keyword = `${this.$route.query.keyword}`;
      this.keyword = keyword;
      this.searchKeyword();
      console.log(type, keyword);
      if (type === 'bookinfo') {
        this.pageType = 'book';
      }
    }
  },
  components: {
    'i-navbar': navbar,
    'i-footer': footer,
    'i-treeview': treeview,
  },
  data() {
    return {
      pageType: 'desp',
      pageTypeList: ['desp', 'full_text', 'book', 'person'],
      personInfo: {},
      personPageType: 'list',
      personPageTypeList: ['list', 'info'],
      keyword: '',
      fullTextSearchResultList: [],
      filterFullTextSearchResultList: [],
      personSearchResultList: [],
      bookSearchResultList: [],
      topicKeywordList: [],
      keywordList: [],
      searchType: '',
      personNicknameList: [],
      singleFullText: {
        title: 'ooo',
        content: 'xxx',
      },
      singleFullTextState: 'off',
    };
  },
  methods: {
    reSortFullTextById() {
      // console.log('reSortFullTextById', this.filterFullTextSearchResultList);

      this.filterFullTextSearchResultList.sort((itemA, itemB) => {
        let itemAId = itemA.id;
        if (itemA.insertToId > -1) {
          itemAId = itemA.insertToId;
        }
        let itemBId = itemB.id;
        if (itemB.insertToId > -1) {
          itemBId = itemB.insertToId;
        }
        return itemAId - itemBId;
      });

      // console.log('finalResult', this.filterFullTextSearchResultList);
    },
    searchKeyword() {
      if (this.keyword === '') {
        return;
      }
      // console.log(this.keyword);
      const pageType = this.pageTypeList[1];
      this.pageType = pageType;
      // console.log(this.pageType);
      this.parseSearchType();
      console.log(this.keywordList, this.searchType);
      const postData = {
        keywordList: this.keywordList,
        searchType: this.searchType,
      };

      if (this.searchType !== '') {
        this.searchFullText(postData);
        this.searchPersonList(postData);
        this.searchBookList(postData);
      }
    },
    searchPerson(personName) {
      // console.log(personName);
      let newPersonName = personName;
      if (personName.includes('highlight')) {
        // extract person name
        // console.log('highlight');
        // <span class="bd-highlight">
        // </span>
        newPersonName = personName.replace('<span class="bd-highlight">', '');
        newPersonName = newPersonName.replace('</span>', '');
      }
      // console.log(newPersonName);
      this.personPageType = 'info';
      const personInfoAPI = `${process.env.VUE_APP_APIPATH}api/biography-info/?person_name=${newPersonName}&format=json`;
      // console.log(personInfoAPI);
      this.$http.get(personInfoAPI).then((response) => {
        // console.log(response.data);
        if (response.data[0]) {
          // console.log(response.data[0]);
          // update person info
          this.personInfo = { ...response.data[0] };
          this.relatedPerson = this.personInfo.relatedPerson.split('、');
        }
      });
    },
    highlightKeyword(content) {
      const keywordReg = new RegExp(this.keyword, 'g');
      return content.replace(
        keywordReg,
        `<span class="bd-highlight">${this.keyword}</span>`,
      );
    },
    highlightKeywordList(content) {
      let content_cp = content;
      this.keywordList.forEach((keyword) => {
        const keywordReg = new RegExp(keyword, 'g');
        content_cp = content_cp.replace(
          keywordReg,
          `<span class="bd-highlight">${keyword}</span>`,
        );
      });
      return content_cp;
    },
    pluginTextResultJQuery() {
      $(document).ready(function () {
        console.log('ready!');
        $('.text-result-content').prop('onclick', null).off('click');
        $('.text-result-content').click(function () {
          console.log('hi', $(this).hasClass('show-text-line-n'));
          const showTextClass = 'show-text-line-n';
          if ($(this).hasClass(showTextClass)) {
            $(this).removeClass(showTextClass);
          } else {
            $(this).addClass(showTextClass);
          }
        });
      });
    },
    filterFullTextSearchResult(selectedList) {
      // console.log('node path list', selectedList);
      if (selectedList && selectedList.length > 0) {
        const selectedTitleFormatList = [];
        selectedList.forEach((selectdItem) => {
          const titleFormat = selectdItem.join('→');
          selectedTitleFormatList.push(titleFormat);
        });
        // console.log('selectedTitleFormatList', selectedTitleFormatList);

        this.filterFullTextSearchResultList = [];
        this.fullTextSearchResultList.forEach((item) => {
          let title = item.title;
          title = title.replace('<span class="bd-highlight">', '');
          title = title.replace('</span>', '');
          title = title.split('→').slice(0, 3);
          title = title.join('→');
          // console.log('fulltext new title', title);
          // console.log(
          //   'fulltext new title is in selectedTitle',
          //   selectedTitleFormatList.includes(title),
          // );

          if (selectedTitleFormatList.includes(title)) {
            this.filterFullTextSearchResultList.push(item);
          }
        });
      } else {
        this.filterFullTextSearchResultList = [];
        this.fullTextSearchResultList.forEach((item) => {
          this.filterFullTextSearchResultList.push(item);
        });
      }
      this.reSortFullTextById();
    },
    dbsearch(type, keyword) {
      // console.log(keyword);
      if (type === 'bookinfo') {
        let bookname = keyword.split('→')[2];
        let div = document.createElement('div');
        div.innerHTML = bookname;
        const text = div.textContent || div.innerText || '';
        bookname = text;

        this.keyword = bookname;
        this.searchKeyword();
        this.pageType = 'book';
      } else if (type === 'fulltext') {
        // console.log(keyword);
        let div = document.createElement('div');
        div.innerHTML = keyword;
        const text = div.textContent || div.innerText || '';
        const bookname = text;
        this.keyword = bookname;
        this.searchKeyword();
        this.pageType = 'full_text';
      }
    },
    linkToPerson(person) {
      console.log(person);
      const queryURL = `/biography?person=${person}`;
      console.log(queryURL);
      this.$router.push(queryURL);
    },
    openBookInfo(type, bookName) {
      if (type === 'fulltext') {
        let bookname = bookName.split('→')[2];
        let div = document.createElement('div');
        div.innerHTML = bookname;
        const text = div.textContent || div.innerText || '';
        bookname = text;

        const queryURL = `/treasure?bookname=${bookname}`;
        this.$router.push(queryURL);
      } else if (type === 'book') {
        let div = document.createElement('div');
        div.innerHTML = bookName;
        const text = div.textContent || div.innerText || '';
        const bookname = text;

        const queryURL = `/treasure?bookname=${bookname}`;
        this.$router.push(queryURL);
      }
    },
    parseSearchType() {
      // search_type:
      // 1. topic keywords
      // 2. 'or' keywords
      // 3. 'and' keywords
      // 4. single keyword
      // 5. person and person nickname search

      this.keywordList = [];

      // 1. topic keywords
      let isTopicType = false;
      this.topicKeywordList.forEach((item) => {
        if (item.topic === this.keyword) {
          this.keywordList = item.keywords;
          this.searchType = 'or';
          isTopicType = true;
        }
      });

      if (isTopicType === false) {
        // 2. 'or' keywords
        if (this.keyword.includes('|')) {
          this.keywordList = this.keyword.split('|');
          this.searchType = 'or';
        }

        // 3. 'and' keywords
        if (this.keyword.includes('&')) {
          this.keywordList = this.keyword.split('&');
          this.searchType = 'and';
        }

        // this section contains two types:
        // 1. singel-word keyword search
        // 2. person and person nickname search
        if (!this.keyword.includes('|') && !this.keyword.includes('&')) {
          // detect isPersonSearch
          // use api to get person nickname list

          const nicknameSearchAPI = `${process.env.VUE_APP_APIPATH}api/person-nickname-search/?name=${this.keyword}`;
          console.log(nicknameSearchAPI);
          this.$http.get(nicknameSearchAPI).then((response) => {
            // console.log(response.data);
            if (response.data[0]) {
              // isPersonSearch
              // console.log(response.data[0]);
              const searchResult = response.data[0];
              this.keywordList = searchResult.nicknames.split('、');
              this.keywordList.push(searchResult.name);
              this.searchType = 'or';
              // console.log('xxxxxx', this.keywordList);
              const postData = {
                keywordList: this.keywordList,
                searchType: this.searchType,
              };
              this.searchFullText(postData);
              this.searchPersonList(postData);
              this.searchBookList(postData);
            } else {
              // is single-keyword search
              this.keywordList.push(this.keyword);
              this.searchType = 'single-keyword';

              const postData = {
                keywordList: this.keywordList,
                searchType: this.searchType,
              };
              this.searchFullText(postData);
              this.searchPersonList(postData);
              this.searchBookList(postData);
            }
          });
        }
      }
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === name + '=') {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    searchFullText(postData) {
      const searchFullTextAPI = `${process.env.VUE_APP_APIPATH}api-post/fulltext-search`;
      const csrftoken = this.getCookie('csrftoken');
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
        // withCredentials: true,
      };
      // console.log('headers', headers);
      this.$http
        .post(searchFullTextAPI, postData, {
          headers: headers,
        })
        .then((response) => {
          // console.log(response.data.data);
          const resultList = response.data.data;
          // heighlight keyword
          resultList.forEach((item, index) => {
            resultList[index].title = this.highlightKeywordList(item.title);
            resultList[index].content = this.highlightKeywordList(item.content);
            resultList[index].isContainImg = item.isContainImg;
            resultList[index].contentWithKeyword = this.cutKeywordContent(
              item.content,
              100,
            );
          });
          this.fullTextSearchResultList = [...resultList];
          // console.log(this.fullTextSearchResultList);
          this.filterFullTextSearchResult();
          // this.pluginTextResultJQuery();
        });
    },
    cutKeywordContent(content, nChar) {
      // nChar = 100 => show keyword previous and last 100 char
      // console.log(this.keywordList);
      // remove content tag

      const div = document.createElement('div');
      div.innerHTML = content;
      let tmpContent = div.textContent || div.innerText || '';

      let newContentList = [];
      this.keywordList.forEach((keyword) => {
        // find same word all index
        const keywordIndexList = [];
        let index = 0;
        let keywordIndex = tmpContent.indexOf(keyword, index);
        while (keywordIndex >= 0) {
          keywordIndexList.push(keywordIndex);
          index = keywordIndex + 1;
          keywordIndex = tmpContent.indexOf(keyword, index);
        }

        if (keywordIndexList.length > 0) {
          // console.log('tmpContent', tmpContent);

          keywordIndexList.forEach((keywordIndex) => {
            // console.log(
            //   'keyword-index, start-index, end-index',
            //   keywordIndex,
            //   keywordIndex - nChar,
            //   keywordIndex + nChar,
            // );
            const startIndex = Math.max(0, keywordIndex - nChar);
            const endIndex = Math.min(keywordIndex + nChar, tmpContent.length);
            let newContent = tmpContent.slice(startIndex, endIndex);
            // newContent = this.highlightKeywordList(newContent);
            newContent = this.highlightIndexRangeSpan(
              [
                keywordIndex - startIndex,
                keywordIndex + keyword.length - endIndex,
              ],
              newContent,
              keyword,
            );
            newContentList.push(newContent);
          });
        }
      });
      // console.log('cutKeywordContent', newContentList);
      const newContent = newContentList.join('<p></p>');
      return newContent;
    },
    highlightIndexRangeSpan(indexRange, span, keyword) {
      // console.log('indexRange', indexRange, 'keyword', keyword);
      // `<span class="bd-highlight">${this.keyword}</span>`
      let newSpan =
        span.slice(0, indexRange[0]) +
        '<span class="bd-highlight">' +
        span.slice(indexRange[0], indexRange[1]) +
        '</span>' +
        span.slice(indexRange[1]);
      return newSpan;
    },
    searchPersonList(postData) {
      // reset person-search page
      this.personPageType = 'list';
      // const searchPersonDatabaseAPI = `${process.env.VUE_APP_APIPATH}api/biography-keyword-search/?keyword=${this.keyword}`;
      const searchPersonDatabaseAPI = `${process.env.VUE_APP_APIPATH}api-post/person-search`;
      const csrftoken = this.getCookie('csrftoken');
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
        // withCredentials: true,
      };
      // console.log(searchPersonDatabaseAPI);
      // this.$http.get(searchPersonDatabaseAPI).then((response) => {
      this.$http
        .post(searchPersonDatabaseAPI, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.data);
          const resultList = response.data.data;
          // highlight keyword
          resultList.forEach((item, index) => {
            resultList[index].name = this.highlightKeywordList(item.name);
            resultList[index].nickname = this.highlightKeywordList(
              item.nickname,
            );
            resultList[index].address = this.highlightKeywordList(item.address);
          });
          this.personSearchResultList = [...resultList];
          console.log(this.personSearchResultList);
        });
    },
    searchBookList(postData) {
      // const searchBookAPI = `${process.env.VUE_APP_APIPATH}api/bookdatabase-keyword-search/?keyword=${this.keyword}`;
      const searchBookAPI = `${process.env.VUE_APP_APIPATH}api-post/bookinfo-search`;
      const csrftoken = this.getCookie('csrftoken');
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
        // withCredentials: true,
      };
      console.log(searchBookAPI);
      // this.$http.get(searchBookAPI).then((response) => {
      this.$http
        .post(searchBookAPI, postData, {
          headers: headers,
        })
        .then((response) => {
          // console.log(response.data.data);
          const resultList = response.data.data;
          // heighlight keyword
          resultList.forEach((item, index) => {
            resultList[index].fourClass = this.highlightKeywordList(
              item.fourClass,
            );
            resultList[index].mainCategory = this.highlightKeywordList(
              item.mainCategory,
            );
            resultList[index].subCategory = this.highlightKeywordList(
              item.subCategory,
            );
            resultList[index].bookName = this.highlightKeywordList(
              item.bookName,
            );
            resultList[index].bookNums = this.highlightKeywordList(
              item.bookNums,
            );
            resultList[index].author = this.highlightKeywordList(item.author);
            resultList[index].version = this.highlightKeywordList(item.version);
            resultList[index].annotator = this.highlightKeywordList(
              item.annotator,
            );
            resultList[index].writer = this.highlightKeywordList(item.writer);
            resultList[index].topic = this.highlightKeywordList(item.topic);
            resultList[index].bookmark = this.highlightKeywordList(
              item.bookmark,
            );
            resultList[index].preface = this.highlightKeywordList(item.preface);
            resultList[index].isContainImg = item.isContainImg;
          });
          this.bookSearchResultList = [...resultList];
          // console.log(this.bookSearchResultList);
        });
    },
    openSingleFullText(item) {
      this.singleFullText = item;
      this.singleFullTextState = 'on';
    },
  },
};
</script>

<style lang="sass" scoped>
.underline_div
  width: 100%
  border-bottom: solid
  border-color: #617d5a

.dropdown_icon
  transform: translate(250%, 10%)

.dropdown_div
  width: 100%
  background-color: #efefef
  a:hover
    background-color: #327474

#related_person
  height: 200px
  overflow-y: auto
  overflow-x: hidden
  .title
    font-size: 16px
    letter-spacing: 2px
    color: #677b7b
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"

#fulltext_dir
  height: 550px
  overflow-y: auto
  overflow-x: auto
  .title
    font-size: 16px
    letter-spacing: 2px
    color: #677b7b
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"

.search_section
  height: 90vh
  padding: 2rem
  border-radius: 8px
  background-color: #efefef
  .title
    font-size: 16px
    letter-spacing: 2px
    color: #677b7b
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  input
    outline: 0
    border-width: 0
    background-color: #efefef
    width: 80%
    font-size: 14px
  .search_btn
    width: 100%
    margin-top: 1rem
    margin-bottom: 1rem
    background-color: #617d5a
    border-color: #617d5a

.relative_person
  padding-top: 0.5rem
  padding-left: 1rem
  span
    display: block
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #29b7db
    font-family: "Microsoft Jheng Hei Regular"
    text-decoration: underline

a.nav-link.active
  padding: 1px 20px
  border-top-left-radius: 20px
  border-top-right-radius: 20px
  background-color: #efefef
  border-color: #efefef
  font-size: 16px
  letter-spacing: 2px
  line-height: 24px
  color: #595757
  font-weight: bold
  font-family: "Microsoft Jheng Hei Bold"

a.nav-link.custom-active
  background-color: #f9f7e7
  border-color: #f9f7e7
  color: #f3a500

#desp_section
  height: 90vh
  padding: 2rem
  border-radius: 8px
  background-color: #efefef
  .title
    font-size: 20px
    color: #677b7b
    font-family: "Microsoft Jheng Hei Regular"
  .content
    font-size: 18px
    letter-spacing: 2px

.show-text-line-n
  width: 700px
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 5
  overflow: hidden

#search_result_section
  .text-result-title
    font-size: 20px
    color: #629697
    font-family: "Microsoft Jheng Hei Regular"
  .text-result-content
    width: 700px
    letter-spacing: 2px
  .text_result_section
    height: 48vh
    overflow-y: auto
    overflow-x: hidden
  .book_result_section
    height: 60vh
    overflow-y: auto
    overflow-x: hidden
  .person_result_section
    height: 60vh
    overflow-y: auto
    overflow-x: hidden

a.nav-link.full-text-active
  background-color: #c2e3e3
  border-color: #c2e3e3
  color: #629697

.full_text_section
  height: 86vh
  background-color: #c2e3e3
  padding-top: 2rem
  .title
    background-color: #629697
    padding-left: 2rem
    font-size: 18px
    letter-spacing: 2px
    line-height: 24px
    color: #ffffff
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  .content
    padding-left: 2rem
    font-size: 16px
    letter-spacing: 2px
    line-height: 24px
    color: #3e3a39
    font-family: "Microsoft Jheng Hei Regular"

a.nav-link.person-database-active
  background-color: #f9f7e7
  border-color: #f9f7e7
  color: #f3a500

.person_database_section
  height: 86vh
  background-color: #f9f7e7
  padding-top: 2rem
  .title
    background-color: #f3a500
    padding-left: 2rem
    font-size: 18px
    letter-spacing: 2px
    line-height: 24px
    color: #ffffff
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  .content
    padding-left: 2rem
    font-size: 16px
    letter-spacing: 2px
    line-height: 24px
    color: #3e3a39
    font-family: "Microsoft Jheng Hei Regular"
  .result_section
    height: 65vh
    overflow: auto
    .text-short
      width: 5vw
    .text-long
      width: 10vw

a.nav-link.book-database-active
  background-color: #dde7eb
  border-color: #dde7eb
  color: #237da9

.book_database_section
  height: 86vh
  background-color: #dde7eb
  padding-top: 2rem
  .title
    background-color: #367189
    padding-left: 2rem
    font-size: 18px
    letter-spacing: 2px
    line-height: 24px
    color: #ffffff
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  .content
    padding-left: 2rem
    font-size: 16px
    letter-spacing: 2px
    line-height: 24px
    color: #3e3a39
    font-family: "Microsoft Jheng Hei Regular"
  .result_section
    height: 65vh
    overflow: auto
    .title-text
      color: #367189
    .first_table_td
      background-color: #c3d3da
    .first_td
      width: 20%
      font-size: 14px
      letter-spacing: 1px
      line-height: 24px
      color: #3e3a39
      font-weight: bold
      font-family: "Microsoft Jheng Hei Bold"
      background-color: #e9f7fe
    .secodn_td
      padding-left: 0.5rem !important
      background-color: #e9f7fe
      font-size: 14px
      letter-spacing: 1px
      line-height: 24px
      color: #3e3a39
      font-family: "Microsoft Jheng Hei Regular"
    td
      border: #dde7eb solid 2px

.right-column
  .table-box
    height: 86vh
    background-color: #f9f7e7
    padding-top: 2rem
    .title
      background-color: #f3a500
      padding-left: 2rem
      font-size: 18px
      letter-spacing: 2px
      line-height: 24px
      color: #ffffff
      font-weight: bold
      font-family: "Microsoft Jheng Hei Bold"
    .content
      padding-left: 2rem
      font-size: 16px
      letter-spacing: 2px
      line-height: 24px
      color: #3e3a39
      font-family: "Microsoft Jheng Hei Regular"

#person_table
  .title
    background-color: #a1a1a1
    font-size: 16px
    letter-spacing: 2px
    line-height: 24px
    color: #fffefe
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"

  .first_td
    width: 20%
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #3e3a39
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
    background-color: #dbdbdb

  .secodn_td
    padding-left: 0.5rem !important
    background-color: #dbdbdb
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #3e3a39
    font-family: "Microsoft Jheng Hei Regular"

  .table-bordered
    td
      border: 2px solid #f9f7e7
      padding: 2px

#person_biography
  .title
    background-color: #a1a1a1
    font-size: 16px
    letter-spacing: 2px
    line-height: 24px
    color: #fffefe
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"

  .content
    background-color: #dbdbdb
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #3e3a39
    font-family: "Microsoft Jheng Hei Regular"
    a
      color: #3e3a39
      text-decoration: underline

#go_back_btn
  position: fixed
  right: 0
  bottom: 0

.database_btn
  background-color: #629697

.fulltext_hide_show_btn
  color: #677b7b
  a
    color: #677b7b

#fulltext_overlap_div
  overflow-y: auto
  overflow-x: hidden
  background-color: #efefef
  position: absolute
  top: 2.5rem
  left: 1.5rem
  width: 94%
  height: 83vh
  border-radius: 15px
  opacity: 0.9
  .title
    display: block
    padding-top: 3rem
    padding-left: 2rem
    color: #629697
    font-family: "Microsoft Jheng Hei Regular"
  .content
    display: block
    padding: 0rem 2rem
    font-size: 18px
</style>

<style scoped>
#search_result_section >>> .bd-highlight {
  background-color: yellow;
}

.text_result_section >>> .ingredient {
  color: red;
  font-size: 8px;
}
</style>
